<script>
/**
 * Page-header component
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    actionTitle: {
      type: String,
      default: "",
    },
    actionIcon: {
      type: String,
      default: "",
    },
    actionVariant: {
      type: String,
      default: "",
    },
    actionItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<template>
  <!-- start page title -->
  <div class="row align-items-center">
    <div class="col-sm-6">
      <div class="page-title-box">
        <h4 class="font-size-18">{{ title }}</h4>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="float-right d-none d-md-block" v-if="actionTitle && actionItems.length > 0">
        <b-dropdown right :variant="actionVariant">
          <template v-slot:button-content>
            <i :class="actionIcon" class="mr-2"></i>
            {{ actionTitle }}
          </template>
          <template v-for="(link, index) in actionItems">
            <b-link class="dropdown-item" :key="index" :to="link.to">{{ link.text }}</b-link>
          </template>
        </b-dropdown>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
