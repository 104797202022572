export default {
  data() {
    return {
      filters: {
        status: [],
        companies: [],
        typeOfApplication: [],
        bank: [],
        dateRange: [],
        typeOfBankAccount: [],
        currency: [],
        isAvailableForTransfer: [],
        isDefault: [],
        isDefaultPrepaidCard: [],
        isActive: [],
        isClosed: [],
        fileType: [],
        responseDateRange: [],
        user: [],
        requestType: [],
        type: [],
        kind: [],
        typeOfRoles: [],
        acceptantCompanies: [],
        applicantCompanies: [],
        grid: {
          filter: "",
          filterOn: [""],
          perPage: 500,
          sortBy: "createdAt",
          sortDesc: true,
          fieldsSelected: [],
        },
      },
      grid: {
        totalRows: 0,
        currentPage: 1,
        perPage: 500,
        pageOptions: [10, 25, 50, 100, 500],
        filter: "",
        filterOn: [""],
        sortBy: "createdAt",
        sortDesc: true,
        fields: [],
      },
    };
  }
};